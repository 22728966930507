<template>
  <div class='upload-pan-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <!-- <div>
            <img src='../../../assets/panImage.png' />
          </div> -->
          <div>
            <div class='upload-pan-title'>Upload PAN</div>
            <div class='upload-pan-stepper'>Hello Raj!</div>

            <div class='upload-pan-sub-title-description'>
              PAN is compulsory for a demat account. We will read data
            </div>
            <div class='upload-pan-sub-title-description'>
              automatically from uploaded PAN card. You can also edit details
              after uploading it.
            </div>
            <div class='upload-pan-upload-btn'>
              <a-upload
                v-model:file-list='fileList'
                name='file'
                action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                :headers='headers'
                @change='handleChange'
              >
                <a-button
                @click="onClickContinue"
                  style='width: 100%; height: 44px; border: 1px solid #79a89b'
                >
                  Upload File
                  <cloud-upload-outlined />
                </a-button>
              </a-upload>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { CloudUploadOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    CloudUploadOutlined,
  },
  setup() {
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    const router = useRouter();
    const onClickContinue = () => {
      router.push('/onboarding/pan/upload');
    };

    return {
      onClickContinue,
      value1: ref(),
      checked: ref(false),
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/uploadPan.scss';
</style>
